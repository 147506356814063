<template>
  <v-autocomplete
    v-model="newValue"
    :search-input.sync="input"
    v-bind="$attrs"
    :label="label"
    :no-data-text="!input ? `Enter ${label} name` : `No ${label} found`"
    hide-selected
  >
    <template v-if="input" #no-data>
      <v-list-item
        v-if="!hideCreate"
        :disabled="!createRules.every((rule) => rule(input))"
        two-line
        @click="$emit('create', input)"
      >
        <v-list-item-content>
          <v-list-item-title>
            Create {{ (label || "").toLowerCase() }}
            <strong>{{ input }}</strong>
          </v-list-item-title>
          <v-list-item-subtitle
            v-if="!createRules.every((rule) => rule(input))"
            class="text-error text-caption"
          >
            Invalid
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="!hideSaveAs"
        two-line
        :disabled="!saveAsRules.every((rule) => rule(input))"
        @click="$emit('save-as', input)"
        ><v-list-item-content>
          <v-list-item-title>
            Save
            {{ (label || "").toLowerCase() }} as
            <strong>{{ input }}</strong>
          </v-list-item-title>
          <v-list-item-subtitle
            v-if="!saveAsRules.every((rule) => rule(input))"
            class="text-error text-caption"
          >
            Invalid
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="!hideRename && newValue"
        :disabled="!renameRules.every((rule) => rule(input))"
        two-line
        @click="$emit('rename', input)"
      >
        <v-list-item-content>
          <v-list-item-title>
            Rename
            {{ (label || "").toLowerCase() }} as
            <strong>{{ input }}</strong>
          </v-list-item-title>
          <v-list-item-subtitle
            v-if="!renameRules.every((rule) => rule(input))"
            class="text-error text-caption"
          >
            Invalid
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: "InputSelect",
  props: {
    label: {
      type: String,
      required: false,
      default: null,
    },
    value: {
      type: Number,
      required: false,
    },
    hideCreate: {
      type: Boolean,
      required: false,
      default: false,
    },
    createRules: {
      type: Array,
      required: false,
      default: () => [],
    },
    hideRename: {
      type: Boolean,
      required: false,
      default: false,
    },
    renameRules: {
      type: Array,
      required: false,
      default: () => [],
    },
    hideSaveAs: Boolean,
    saveAsRules: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      inputVal: null,
    };
  },
  computed: {
    input: {
      get() {
        return this.inputVal;
      },
      set(input) {
        this.inputVal = input;
        this.$emit("update:search-input", input);
      },
    },
    newValue: {
      get() {
        // TODO: reassess this side-effect and whether it is necessary
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        if (this.value == null) this.input = null;
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {},
  methods: {},
};
</script>

<style scoped>
.text-error {
  color: #d50000 !important;
}
</style>
